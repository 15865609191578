<template>
  <section id="content">
    <base-card>
      <v-card-title>
        <h2>Who We Are</h2>
      </v-card-title>
      <v-card-text>
        Founded in 2019, but idea of bringing us together begin to brew in 2017, IT Fabrik is software and IoT development company dedicated to bringing you solutions that we are proud of. Full
        automation and industrialization of processes and services are our ultimate goals.
        We are available for hire in a wide range of creative disciplines for a variety of jobs, projects and gigs.
      </v-card-text>
      <v-card-title>
        <h2>What We Do</h2>
      </v-card-title>
      <v-card-text>
        We collaborate with you to create hardware and software platforms that works for you, not the other way around.
        Think of us as a partner that will solve your business problems and make your job easier. This means we have
        shared perspective on how we can work together to achieve your goals.<br>
        <router-link to="/projects">
          Check out our Projects
        </router-link>
      </v-card-text>
      <v-card-title>
        <h2>The Team</h2>
      </v-card-title>
      <v-card-text>
        With out diverse team of experience project managers, developers, testers, idea makers, solution providers we
        guarantee to deliver products of the highest quality.<br>
        <router-link to="/team">
          The team behind it all
        </router-link>
      </v-card-text>
      <br>
      <v-card-text>
        <h4><strong>Imprint</strong></h4>
        <br>
        <p><strong>IT Fabrik Doo</strong></p>
        <p>Aleksandra Dubčeka 28h</p>
        <p>11080 Belgrade</p>
        <p>
          <v-icon small>
            mdi-phone
          </v-icon> +381 63 366 184
        </p>
        <p>
          <v-icon small>
            mdi-email
          </v-icon> office@itfabrik.rs
        </p>
        <br>

        <p>Identification number: 21474231</p>
        <p>VAT identification number: 111390232</p>
        <p>Code and purpose of the business: 6201 - Computer programming</p>
      </v-card-text>
    </base-card>
  </section>
</template>

<script>
  export default {
    name: 'Contents'
  }
</script>

<style scoped>
p {
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}
</style>
